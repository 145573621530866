interface appConfig {
    api_url: string,
    projectId: string,
    defaultPageSize: number,
    defaultRowsPerPageOptions: number[],
    autoHeight: boolean;
    tableHeight: any;
    shrinkTableHeight: any;
    mBorder: any;
    version: string,
    mInternalPadding: any;
    snackbarTimeout: number;
}

const AppConfig: appConfig = {
    api_url: (process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_LIVE) ?? 'https://portal.tkb-kakuma.com',
    // api_url: window.location.origin,
    projectId: "1",
    version: 'V0.0.1',
    defaultPageSize: 50,
    defaultRowsPerPageOptions: [100, 200, 400],
    autoHeight: false,
    tableHeight: '70vh',
    shrinkTableHeight: '50vh',
    mBorder: '1px solid rgb(216,216,216)',
    mInternalPadding: 1,
    snackbarTimeout: 3000,
}

export default AppConfig;
